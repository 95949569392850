import Vue from 'vue'
import WalletConnectV2 from '@/partials/WalletConnectV2/WalletConnectV2.vue'
import { Blockchain, BLOCKCHAIN_OPTIONS_EVM, BlockchainLxIconSrc } from '@/constants/blockchain'

export default Vue.extend<any, any, any, any>({
  name: 'lx-walletconnect-choose-network',
  components: {
    WalletConnectV2,
  },
  props: {
    confirm: Function
  },
  data () {
    return {
      BLOCKCHAIN_OPTIONS_EVM,
      selected: {},
    }
  },
  computed: {
    atLeastOneSelected () {
      return Object.keys(this.selected).some((key: string) => this.selected[key])
    }
  },
  methods: {
    async onConnect () {
      const blockchains: Blockchain[] = Object
        .keys(this.selected)
        .filter((key: string) => this.selected[key])
        .map((key: string) => parseInt(key))
      this.confirm(blockchains)
    },
    getBlockchainIcon (blockchain: Blockchain) {
      return BlockchainLxIconSrc[blockchain]
    },
    chooseBlockchain (blockchain: Blockchain) {
      if (this.selected[blockchain]) {
        this.selected = {
          ...this.selected,
          [blockchain]: false
        }
      } else {
        this.selected = {
          ...this.selected,
          [blockchain]: true
        }
      }
    }
  },
})
