
import cloneDeep from 'lodash/cloneDeep'
import File, { FileFromServer } from '@/models-ts/File'

export default class FullTimeInfo {
  position: string
  years: number | string
  cv: Array<File>

  constructor (
    {
      position = '',
      years = '',
      cv = [],
    }: Partial<FullTimeInfo>
  ) {
    Object.assign(this, cloneDeep({
      position,
      years,
      cv,
    }))
  }

  static fromServer (props: FullTimeInfoServerProps) {
    return new FullTimeInfo({
      position: props.position || '',
      years: props.years_of_experience || '',
      cv: (props.relations.Cv || []).map(File.fromServer),
    })
  }

  toServer () {
    return {
      position: (this.position || '').trim(),
      years_of_experience: this.years || undefined,
      relations: {
        Cv: this.cv
      },
    }
  }
}

export type FullTimeInfoServerProps = {
  position: string | null
  years_of_experience: number | string | null
  relations: {
    Cv: Array<FileFromServer> | null
  }
}
