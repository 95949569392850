import VueTypes from 'vue-types'
import { mapActions, mapState } from 'vuex'
import {
  isActive as isActiveMetamask,
  eventEmitter as eventEmitterMetamask,
  getAddress as getAddressMetamask,
} from '@/servicies/blockchain/metamask'
import * as buttons from '@/constants/components/button'
import responseMixin from '@/mixins/responseMixin'
import snackMixin from '@/mixins/snackMixin'
import { BUTTON_BLUE_SMALL, BUTTON_TRANSPARENT_BLUE_SMALL } from '@/constants/components/button'
import ErrorMatcher from '@/utils/ErrorMatcher'
import notifiableRequest from '@/utils/notifiableRequest'
import WalletConnectV2 from '@/partials/WalletConnectV2/WalletConnectV2.vue'
import { WalletGroup } from '@/constants/blockchain'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Button from '@/models-ts/ui/Button'
import TronLinkProvider from '@/servicies-ts/blockchain/tron/provider-tronlink'
import { getWalletGroupName, getWalletIcon } from '@/utils-ts/wallet'
import { getWalletProviderConnectV2Async } from '@/servicies-ts/blockchain/provider-walletconnect-v2'

export default {
  name: 'lx-wallet-add-modal-new',
  mixins: [responseMixin, snackMixin],
  components: {
    WalletConnectV2,
  },
  props: {
    walletType: VueTypes.oneOf([WalletGroup.WalletConnect, WalletGroup.Metamask, WalletGroup.TronLink]),
  },
  data () {
    return {
      account: null,
      address: null,
      walletName: '',
      buttons,
      accountsChangedEventCb: null,
      connectEventCb: null,
      disconnectEventCb: null,
      isActiveMetamask: false,
      isConnectedWalletConnect: false,
      isConnectingWalletConnect: false,
      isAvailableTronLink: false,
      isConnectedTronLink: false,
      WalletGroup,
      BUTTON_BLUE_SMALL,
      BUTTON_TRANSPARENT_BLUE_SMALL,
      accountsChanged: null,
      wcV2UpdateItems: null
    }
  },
  computed: {
    ...mapState({
      wallets: state => state.user?.wallets,
      walletAddLoading: state => state.user?.walletAddLoading,
    }),
    isAddressAlreadyUsed () {
      const address = this.address ? this.address.toLowerCase() : null
      return (this.wallets || []).find(item => item.address.toLowerCase() === address)
    },
    hasForm () {
      switch (this.walletType) {
        case WalletGroup.Metamask: return !!this.address
        case WalletGroup.WalletConnect: return this.isConnectedWalletConnect
        case WalletGroup.TronLink: return this.isAvailableTronLink && this.isConnectedTronLink
        default: return false
      }
    },
    isWalletTypeMetamask () {
      return this.walletType === WalletGroup.Metamask
    },
    isWalletTypeWalletConnect () {
      return this.walletType === WalletGroup.WalletConnect
    },
    isWalletTypeTronLink () {
      return this.walletType === WalletGroup.TronLink
    }
  },
  async created () {
    this.accountsChanged = (accounts) => {
      if (accounts && accounts.length > 0) {
        this.address = accounts[0]
      } else {
        this.address = null
      }
    }
    switch (this.walletType) {
      case WalletGroup.Metamask:
        this.isActiveMetamask = isActiveMetamask()
        eventEmitterMetamask.on('accountsChanged', this.accountsChanged)
        this.address = await getAddressMetamask({})
        break
      case WalletGroup.WalletConnect:
        this.connectEventCb = (session) => {
          this.isConnectedWalletConnect = true
          if (session?.chainIds && session?.addresses) {
            this.address = session?.addresses[0]
          }
        }
        this.disconnectEventCb = () => {
          this.isConnectedWalletConnect = false
        }
        this.wcV2UpdateItems = ({ addresses }) => {
          console.log('WalletAdd addresses', addresses)
          if (addresses && addresses.length > 0) {
            this.address = addresses[0]
          } else {
            this.address = null
          }
        }
        const modelWalletConnect = await getWalletProviderConnectV2Async()
        // this.isConnectedWalletConnect = await provider.isConnected()
        modelWalletConnect.events.on('updatedItems', (data) => console.log('data', data)) // this.wcV2UpdateItems)
        modelWalletConnect.events.on('connect', this.connectEventCb)
        modelWalletConnect.events.on('disconnect', this.disconnectEventCb)
        // this.address = await provider.address()
        break
      case WalletGroup.TronLink:
        await this.connectTronLink()
        break
    }
  },
  destroyed () {
    switch (this.walletType) {
      case WalletGroup.Metamask:
        eventEmitterMetamask.removeListener('accountsChanged', this.accountsChanged)
        break
      case WalletGroup.TronLink:
        eventEmitterMetamask.removeListener('accountsChanged', this.accountsChanged)
        TronLinkProvider.events.on('connect', this.connectEventCb)
        TronLinkProvider.events.on('disconnect', this.disconnectEventCb)
        break
    }
  },
  methods: {
    ...mapActions({
      setDefaultWallet: 'user/setDefaultWallet',
      addWallet: 'user/addWallet',
      closeModal: 'ui/closeModal',
      openModal: 'ui/openModal',
    }),
    getWalletGroupName,
    getWalletIcon,
    async connectTronLink () {
      try {
        TronLinkProvider.initWithoutAwait()
        await TronLinkProvider.connect()
        this.connectEventCb = () => {
          this.isConnectedTronLink = true
        }
        this.disconnectEventCb = () => {
          this.isConnectedTronLink = false
        }
        this.isAvailableTronLink = TronLinkProvider.isAvailable
        this.isConnectedTronLink = TronLinkProvider.isConnected
        this.address = await TronLinkProvider.address()
        TronLinkProvider.events.on('accountsChanged', this.accountsChanged)
        TronLinkProvider.events.on('connect', this.connectEventCb)
        TronLinkProvider.events.on('disconnect', this.disconnectEventCb)
      } catch (err) {
        console.error(err)
      }
    },
    async onAddWallet () {
      if (await this.$validator.validateAll()) {
        if (this.isAddressAlreadyUsed) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'This wallet address has already been added.',
          })
          return
        }
        await notifiableRequest({
          request: () => this.addWallet({
            type: this.walletType === WalletGroup.TronLink ? 18 : 2,
            group: this.walletType,
            address: this.address,
            name: this.walletName,
          }),
          title: 'Add wallet',
          successText: `Wallet added successfully.`,
          failureText: (e) => {
            if (ErrorMatcher.isUnprocessableEntity(e)) {
              return 'This wallet is already being used for another account.'
            }
            return e?.response?.data?.validation?.wallet || 'Error adding wallet. Please try again.'
          }
        })
        googleAnalyticsV2.send({
          event: 'settings-wallet-added',
          name: this.walletName,
        })
        this.closeModal()
        this.setAsDefault()
      }
    },
    setAsDefault () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Use as default?',
          text: `Change <b>${this.walletName}</b> to your default wallet? `,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
              onClick: () => {}
            }),
            new Button({
              text: 'Confirm',
              classes: 'lx-blue-btn',
              onClick: async () => {
                await notifiableRequest({
                  request: async () => {
                    await this.setDefaultWallet({ address: this.address })
                  },
                  title: 'Wallet',
                  successText: `Default wallet address successfully updated.`,
                  failureText: (err) => err?.response?.data?.validation?.wallet || 'Error setting default wallet. Please try again.'
                })
              },
            }),
          ]
        }
      })
    },
  },
}
