import get from 'lodash/get'
import { mapActions } from 'vuex'
import { readImage } from '@/utils/file'
import responseMixin from '@/mixins/responseMixin'
import snackMixin from '@/mixins/snackMixin'

export default {
  name: 'lx-update-avatar-modal',
  mixins: [snackMixin, responseMixin],
  props: ['image'],
  data () {
    return {
      processing: true,
      saving: false,
      imgSrc: null,
      fileData: null,
    }
  },
  async created () {
    if (typeof FileReader === 'function') {
      const fileData = await readImage(this.image)
      this.fileData = fileData
      this.imgSrc = fileData.base64
      this.processing = false
    } else {
      alert('Sorry, FileReader API not supported') // ToDo: open own warning dialog
    }
  },
  methods: {
    ...mapActions({
      updateAvatar: 'user/updateAvatar',
    }),
    async onSave () {
      this.saving = true
      const cropper = get(this.$refs, 'cropper.$children[0]')
      if (cropper) {
        const arr = [...cropper.$el.children]
        const img = arr.find(el => el.className === 'cropper-hidden')
        const { naturalHeight, naturalWidth } = img
        const data = cropper.getCropData()
        const x = Math.max(data.x, 0)
        const y = Math.max(data.y, 0)
        const w = data.w <= 0 ? naturalWidth : data.w
        const h = data.h <= 0 ? naturalHeight : data.h
        try {
          await this.updateAvatar({ x, y, w, h, avatar: this.image })
          this.openSnackbar({
            type: this.SnackTypes.SUCCESS,
            text: 'Your profile has successfully been updated',
          })
          this.$emit('close')
        } catch (e) {
          this.parseError(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
}
