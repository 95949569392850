import VueTypes from 'vue-types'
import { getQuizByName, sendVote } from '@/api/quiz'
import snackMixin from '@/mixins/snackMixin'
import * as quizzes from '@/constants/components/quizzes'

export default {
  name: 'lx-quiz-modal',
  mixins: [snackMixin],
  props: {
    meta: Object,
    type: VueTypes.oneOf(Object.values(quizzes)).isRequired,
  },
  data () {
    return {
      preloading: true,
      sending: false,
      answered: false,
      quiz: null,
      answer: null,
      comment: '',
    }
  },
  computed: {
    options () {
      return this.quiz.relations.Variant
    },
    isOther () {
      return this.answer?.name && this.answer?.name.includes('Other')
    },
  },
  async created () {
    try {
      this.quiz = await getQuizByName(this.type)
      this.preloading = false
    } catch (e) {
      console.error(e)
      this.$emit('close')
    }
  },
  methods: {
    onInputOption () {
      if (this.isOther) {
        this.$nextTick(() => {
          const commentEl = this.$refs.comment?.$el
          if (commentEl) {
            const textarea = commentEl.querySelector('textarea')
            if (textarea) {
              textarea.focus()
              textarea.scrollIntoViewIfNeeded()
            }
          }
        })
      }
    },
    async onSubmit () {
      try {
        this.sending = true
        await sendVote({
          quiz_id: this.quiz.id,
          variant_id: this.isOther ? null : this.answer.id,
          comment: this.isOther ? this.comment : null,
          meta: {
            ...this.meta,
          },
        })
        this.answered = true
        setTimeout(() => this.$emit && this.$emit('close'), 3000)
      } catch (e) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error submitting review. Please try again.',
        })
      } finally {
        this.sending = false
      }
    },
  },
}
