import Vue from 'vue'
import { mapActions } from 'vuex'
import WalletConnectChooseNetworks from '@/modals/WalletConnectChooseNetworks/WalletConnectChooseNetworks.vue'
import { Blockchain, ChainId, getBlockchainNameByChainId } from '@/constants/blockchain'
import { getWalletProviderConnectV2Async } from '@/servicies-ts/blockchain/provider-walletconnect-v2'

interface IData {
  isWalletConnectConnected: boolean,
  isWalletConnectConnecting: boolean,
  listenerConnect: null | ((session: any) => void),
  listenerDisconnect: null | (() => void),
  listenerUpdatedItems: null | ((data: any) => void),
  connectedChainIds: any[],
  addresses: string[],
  chainIds: string[],
}

export default Vue.extend({
  name: 'lx-wallet-connect-v2',
  data (): IData {
    return {
      isWalletConnectConnected: false,
      isWalletConnectConnecting: false,
      listenerConnect: null,
      listenerDisconnect: null,
      listenerUpdatedItems: null,
      connectedChainIds: [],
      addresses: [],
      chainIds: [],
    }
  },
  async created () {
    const provider = await getWalletProviderConnectV2Async()
    this.isWalletConnectConnected = provider.isConnected()
    const walletData: any = await provider.getChainIdsAndAddresses()
    this.addresses = walletData?.addresses || []
    this.chainIds = walletData?.chainIds || []
    this.listenerConnect = (session: any) => {
      this.isWalletConnectConnected = true
      if (session?.chainIds && session?.addresses) {
        this.addresses = session?.addresses
        this.chainIds = session?.chainIds
      }
    }
    this.listenerDisconnect = () => {
      this.isWalletConnectConnected = false
    }
    this.listenerUpdatedItems = ({ chainIds, addresses }: { chainIds: number[], addresses: string[] }) => {
      this.addresses = addresses
      this.chainIds = chainIds
    }
    provider.events.on('connect', this.listenerConnect || (() => {
    }))
    provider.events.on('disconnect', this.listenerDisconnect || (() => {
    }))
    provider.events.on('updatedItems', this.listenerUpdatedItems || (() => {
    }))
  },
  async destroyed () {
    const provider = await getWalletProviderConnectV2Async()
    provider.events.removeListener('connect', this.listenerConnect || (() => {
    }))
    provider.events.removeListener('disconnect', this.listenerDisconnect || (() => {
    }))
    provider.events.removeListener('updatedItems', this.listenerUpdatedItems || (() => {
    }))
  },
  computed: {
    connectedNetworksTags () {
      return this.chainIds
        .map((chainId: string) => {
          return getBlockchainNameByChainId(parseInt(chainId))
        })
        .filter((item: string) => !!item)
        .map((text: string) => ({ text }))
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
    }),
    async connectWalletConnect () {
      const modalId = await this.openModal({
        component: WalletConnectChooseNetworks,
        props: {
          confirm: (blockchains: Blockchain[]) => {
            this.confirmSelectBlockchains(blockchains)
            this.closeModal(modalId)
          }
        }
      })
    },
    async confirmSelectBlockchains (blockchains: Blockchain[]) {
      try {
        const walletConnectProvider = await getWalletProviderConnectV2Async()
        const chainIds = blockchains.map((blockchain) => Number(ChainId[blockchain]))
        const session = await walletConnectProvider.sessionConnect(chainIds)
      } catch (err) {
        console.log('err', err)
      }
    },
    async disconnect () {
      const walletConnectProvider = await getWalletProviderConnectV2Async()
      walletConnectProvider.disconnect()
    }
  }
})
