import Vue from 'vue'
import zendeskMixin from '@/mixins/zendeskMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [zendeskMixin],
  name: 'lx-you-blocked-modal',
  props: {
  },
  data: () => ({
  }),
  methods: {
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
        this.$emit('close')
      }
    },
  }
})
