import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import copy from 'copy-to-clipboard'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import { USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'

export default Vue.extend<any, any, any, any>({
  name: 'lx-share-profile-modal',
  props: {
    name: String,
    profile: [MyFreelancerProfile, MyCustomerProfile],
  },
  data: () => ({
    hashtags: [
      'CryptoFreelance',
      'CryptoJobs',
      'CryptoJob',
      'BlockchainJob',
    ],
    copied: false,
  }),
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    profileUrl () {
      return this.isFreelancer
        ? `/freelancers/users/id${this.userId}`
        : `/customers/users/id${this.userId}`
    },
    shareUrl () {
      const ref = this.userId || this.$route.query.ref
      return `${process.env.VUE_APP_FRONTEND_URL + this.profileUrl}${ref ? `?ref=${ref}` : ''}`
    },
    freelancerText () {
      let text = ''
      const profile = this.profile as MyFreelancerProfile
      if (profile.generalInfo.firstName && profile.generalInfo.lastName) {
        text += `${profile.generalInfo.firstName} ${profile.generalInfo.lastName}.`
      }
      const profession = profile.freelanceInfo.specialization || profile.fulltimeInfo.position
      if (text && profession) {
        text += ` ${profession}.`
      }
      if (this.countriesIsLoaded) {
        const country = (this.countries || []).find((c: any) => c.id === profile.generalInfo.internalCountryId)
        text += country ? ` ${country.name}.` : ''
      }
      return text
    },
    individualCustomerText () {
      let text = ''
      const profile = this.profile as MyCustomerProfile
      if (profile.generalInfo.firstName && profile.generalInfo.lastName) {
        text += `${profile.generalInfo.firstName} ${profile.generalInfo.lastName}.`
      }
      if (this.countriesIsLoaded) {
        const country = (this.countries || []).find((c: any) => c.id === profile.individualInfo.internalCountryId)
        text += country ? ` ${country.name}.` : ''
      }
      return text
    },
    companyCustomerText () {
      let text = ''
      const profile = this.profile as MyCustomerProfile
      if (profile.companyInfo.companyName) {
        text += `${profile.companyInfo.companyName}.`
      }
      const country = profile.companyInfo.countries[0]?.name
      if (text && country) {
        text += ` ${country}.`
      }
      return text
    },
    text () {
      if (this.profile instanceof MyFreelancerProfile) {
        return this.freelancerText
      } else if (this.profile instanceof MyCustomerProfile) {
        return this.profile.type === USER_TYPE_CUSTOMER_PERSON ? this.individualCustomerText : this.companyCustomerText
      }
      return ''
    },
    facebookLink () {
      const hashtag = this.hashtags[0]
      // eslint-disable-next-line
      const link = `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_FACEBOOK_APP_ID}&href=${this.shareUrl}&display=popup&hashtag=%23${hashtag}&redirect_uri=${this.shareUrl}`
      return this.text ? `${link}&quote=${this.text}` : link
    },
    twitterLink () {
      const hashtags = this.hashtags.join(',')
      const link = `https://twitter.com/intent/tweet?jhashtags=${hashtags}`
      return this.text ? `${link}&text=${this.text}` : link
    },
    telegramLink () {
      const link = `https://telegram.me/share/url?url=${this.shareUrl}`
      return this.text ? `${link}&text=${this.text}` : link
    },
    linkedinLink () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.shareUrl}`
    },
  },
  async created () {
    try {
      this.getCountries()
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapActions({
      getCountries: 'countries/getCountries',
    }),
    copyUrlToClipboard () {
      copy(this.shareUrl)
      this.copied = true
    },
    onHide () {
      setTimeout(() => {
        this.copied = false
      }, 500)
    },
  }
})
