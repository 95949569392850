import cloneDeep from 'lodash/cloneDeep'
import PortfolioProject, { PortfolioProjectFromServerProps } from '@/models-ts/user/PortfolioProject'
import GeneralInfo, { GeneralInfoServerProps } from './GeneralInfo'
import Channel, { ChannelFromServer } from '@/models-ts/user/Channel'
import WorkExperience, { WorkExperienceFromServerProps } from '@/models-ts/user/WorkExperience'
import Education, { EducationFromServerProps } from '@/models-ts/user/Education'
import FreelanceInfo, { FreelanceInfoServerProps } from './FreelanceInfo'
import FullTimeInfo, { FullTimeInfoServerProps } from './FullTimeInfo'

export default class MyFreelancerProfile {
  generalInfo: GeneralInfo
  freelanceInfo: FreelanceInfo
  fulltimeInfo: FullTimeInfo
  workExperience: Array<WorkExperience>
  educations: Array<Education>
  channels: Array<Channel>
  portfolio: Array<PortfolioProject>

  constructor (
    {
      generalInfo,
      workExperience,
      freelanceInfo,
      fulltimeInfo,
      educations,
      channels,
      portfolio = []
    }: Partial<MyFreelancerProfile>
  ) {
    Object.assign(this, cloneDeep({ generalInfo, freelanceInfo, workExperience, educations, channels, portfolio, fulltimeInfo }))
  }

  static fromServer (props: MyFreelancerProfileFromServerProps) {
    return new MyFreelancerProfile({
      generalInfo: GeneralInfo.fromServer(props),
      freelanceInfo: FreelanceInfo.fromServer(props),
      fulltimeInfo: FullTimeInfo.fromServer(props),
      educations: (props.relations.Education || []).map(Education.fromServer),
      channels: (props.relations.Channel || []).map(Channel.fromServer),
      workExperience: (props.relations.Experience || []).map(WorkExperience.fromServer),
      portfolio: (props.relations.Portfolio || []).map(PortfolioProject.fromServer),
    })
  }
}

export type MyFreelancerProfileFromServerProps = GeneralInfoServerProps & FreelanceInfoServerProps & FullTimeInfoServerProps & {
  profession: string | null
  rate: string | null
  dob: string | null
  relations: {
    Channel: Array<ChannelFromServer> | null
    Experience: Array<WorkExperienceFromServerProps> | null
    Education: Array<EducationFromServerProps> | null
    Portfolio: Array<PortfolioProjectFromServerProps> | null
  }
}
