
import cloneDeep from 'lodash/cloneDeep'
import PrefferedCurrency, { PrefferedCurrencyServerProps } from '../../PrefferedCurrency'

export default class FreelanceInfo {
  specialization: string
  rate: string
  currencies: Array<PrefferedCurrency>

  constructor (
    {
      specialization = '',
      rate = '',
      currencies = [],
    }: Partial<FreelanceInfo>
  ) {
    Object.assign(this, cloneDeep({
      specialization,
      rate,
      currencies,
    }))
  }

  static fromServer (props: FreelanceInfoServerProps) {
    return new FreelanceInfo({
      specialization: props.specialization || '',
      rate: props.rate || '',
      currencies: (props.relations.Currency || [])
        .map(PrefferedCurrency.getFromSrever)
        .filter(Boolean) as Array<PrefferedCurrency>,
    })
  }

  toServer () {
    return {
      specialization: (this.specialization || '').trim(),
      rate: this.rate || undefined,
      relations: {
        Currency: this.currencies.map(curr => curr.toServer())
      },
    }
  }
}

export type FreelanceInfoServerProps = {
  specialization: string | null
  rate: string | null
  relations: {
    Currency: Array<PrefferedCurrencyServerProps> | null
  }
}
