/* eslint-disable max-len */
import { mapActions, mapState } from 'vuex'
import get from 'lodash/get'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import { AFTER_DELETE } from '@/constants/components/quizzes'
import { LANDING } from '@/constants/routes'
import snackMixin from '@/mixins/snackMixin'
import { isActive as isActiveMetamask, getSignPhrase } from '@/servicies/blockchain/metamask'

export default {
  name: 'lx-delete-account-modal',
  mixins: [responseMixin, snackMixin],
  data () {
    return {
      password: '',
      confirmed: false,
      deletion: false,
      deleteError: false,
      email: '',
      signData: null,
      isDeleting: false,
    }
  },
  computed: {
    ...mapState({
      isSocialAuth: state => state.user?.isSocialAuth,
      isMetamask: state => state.user?.isCrypto,
    }),
  },
  created () {
    this.email = this.$store.state.user.email
  },
  methods: {
    ...mapActions({
      deleteRequest: 'user/deleteAccount',
      openModal: 'ui/openModal',
    }),
    async afterDeletion () {
      this.$emit('close')
      await this.$router.push({ name: LANDING })
      this.openSnackbar({
        text: 'Your account has been successfully deleted. Thank you for using LaborX.',
      })
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/Quiz/Quiz.vue'),
          props: {
            type: AFTER_DELETE,
            meta: {
              email: this.email,
            }
          }
        }
      })
    },
    async deleteAccount () {
      try {
        await this.deleteRequest({ password: this.password })
        this.afterDeletion()
      } catch (e) {
        if (ErrorMatcher.is2FA(e)) {
          this.openModal({
            component: 'lx-confirm-2fa-modal',
            props: {
              confirm: async (key) => {
                await this.deleteRequest({ password: this.password, key })
                this.afterDeletion()
              }
            }
          })
        } else {
          throw e
        }
      }
    },
    async onClickConfirm () {
      if (this.isSocialAuth) {
        try {
          this.deletion = true
          await this.deleteAccount()
        } catch (e) {
          console.error(e)
          this.deleteError = true
        } finally {
          this.deletion = false
        }
      } else if (this.isMetamask) {
        await this.deleteMetamaskAccount()
      } else {
        this.confirmed = true
      }
    },
    async deleteMetamaskAccount () {
      try {
        this.deletion = true
        if (!isActiveMetamask()) {
          this.openModal({
            component: 'lx-composite-modal-new',
            props: {
              title: 'Metamask not installed',
              text: `To continue, you need to install and configure
              <a class="lx-new-link" href="https://metamask.io" target="_blank" rel="nofollow noopener">Metamask</a>
              ${this.isTabletLx ? '<br><br>If you have Meta Mask mobile application installed, please go to the application and use Browser feature' : ''}`,
              onClose: () => { this.deletion = false }
            }
          })
          return
        }
        this.signData = await getSignPhrase()
        await this.onDeleteMetamask()
      } catch (e) {
        this.deletion = false
        throw e
      }
    },
    async onDeleteMetamask () {
      try {
        await this.deleteRequest(this.signData)
        this.afterDeletion()
      } catch (e) {
        if (ErrorMatcher.is2FA(e)) {
          this.openModal({
            component: 'lx-confirm-2fa-modal',
            props: {
              confirm: async (key) => {
                await this.deleteRequest({
                  address: this.signData.address,
                  message: this.signData.message,
                  signature: this.signData.signature,
                  key,
                })
                this.afterDeletion()
              },
              cancel: () => {
                this.deletion = false
              }
            }
          })
        } else {
          if (ErrorMatcher.isConflict(e)) {
            const defaultErr = 'Error deleting your account. Please try again.'
            this.openSnackbar({
              type: this.SnackTypes.FAILURE,
              text: get(e, 'response.data.message', defaultErr),
            })
          }
          throw e
        }
      } finally {
        this.deletion = false
      }
    },
    async onSubmitDeletion () {
      try {
        this.deletion = true
        if (await this.$validator.validateAll('confirmForm')) {
          await this.deleteAccount()
        }
      } catch (e) {
        this.parseError(e, 'confirmForm')
        if (this.errors.count() === 0) {
          this.deleteError = true
        }
      } finally {
        this.deletion = false
      }
    },
  },
}
