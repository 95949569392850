import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { signUp } from '@/api/usersAndProfiles/auth'
import { linkedInAnalytics, googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { Roles } from '@/constants/user/roles'
import recaptchaMixin from '@/mixins/recaptchaMixin'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [recaptchaMixin, snackMixin],
  name: 'lx-change-email-modal',
  props: {
    onSuccess: Function
  },
  data: () => ({
    newEmail: '',
    newEmailConfirm: '',
    isChanging: false,
    loadingCaptcha: false,
    recaptchaTimeout: null,
  }),
  computed: {
    ...mapState({
      signupData: (state: any) => state.signup?.signupData,
    }),
    email () {
      return this.signupData?.email
    }
  },
  methods: {
    ...mapMutations({
      updateSignupData: 'signup/setData',
      setParams: 'analytics/setParams',
      setTriggerCondition: 'analytics/setTriggerCondition',
    }),
    async onSubmit () {
      this.isChanging = true
      this.errors.clear()
      if (await this.$validator.validateAll()) {
        this.loadingCaptcha = true
        this.executeRecaptcha(this.signUp)
        this.recaptchaTimeout = setTimeout(() => {
          this.loadingCaptcha = false
        }, 3000)
      } else {
        this.isChanging = false
      }
    },
    async signUp (reCaptcha: string) {
      if (this.recaptchaTimeout) {
        clearTimeout(this.recaptchaTimeout)
      }
      this.captchaCode = reCaptcha
      try {
        const data = { ...this.signupData, email: this.newEmail }
        await signUp(data, reCaptcha)
        this.updateSignupData(data)
        this.sendAnalytics()
        this.onSuccess?.()
        this.$emit('close')
      } catch (e) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `Error sending request`,
        })
      } finally {
        this.isChanging = false
      }
    },
    sendAnalytics () {
      const role = this.signupData.account_type === Roles.CUSTOMER ? 'Customer' : 'Talent'
      googleAnalyticsV2.send({
        event: 'register-form-email-send',
        role,
        'hit-role': role,
      })
      linkedInAnalytics.sendSuccessRegistration()
      const analyticParams = {
        event: 'register-success',
        name: 'email',
        account: 'Y',
        role,
        'hit-role': role,
      }
      this.setParams(analyticParams)
      this.setTriggerCondition('register-success')
    },
  }
})
