import cloneDeep from 'lodash/cloneDeep'
import Skill, { SkillFromServer } from '@/models-ts/Skill'
import { USER_TYPE_CUSTOMER_COMPANY, USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'
import GeneralInfo, { GeneralInfoServerProps } from './GeneralInfo'
import IndividualInfo, { IndividualInfoServerProps } from './IndividualInfo'
import Channel, { ChannelFromServer } from '@/models-ts/user/Channel'
import CompanyInfo, { CompanyInfoServerProps } from './CompanyInfo'
import PrefferedCurrency, { PrefferedCurrencyServerProps } from '../../PrefferedCurrency'

export default class MyCustomerProfile {
  roleWasSet: Boolean
  generalInfo: GeneralInfo
  individualInfo: IndividualInfo
  companyInfo: CompanyInfo
  skills: Array<Skill>
  currencies: Array<PrefferedCurrency>
  channels: Array<Channel>
  type: typeof USER_TYPE_CUSTOMER_PERSON | typeof USER_TYPE_CUSTOMER_COMPANY

  constructor (
    {
      roleWasSet = false,
      generalInfo,
      individualInfo,
      companyInfo,
      skills = [],
      currencies = [],
      channels = [],
      type = USER_TYPE_CUSTOMER_PERSON
    }: Partial<MyCustomerProfile>
  ) {
    Object.assign(this, cloneDeep({ roleWasSet, generalInfo, individualInfo, companyInfo, skills, currencies, channels, type }))
  }

  static fromServer (props: MyCustomerProfileFromServerProps) {
    return new MyCustomerProfile({
      type: props.type,
      roleWasSet: props.first_save === 1,
      generalInfo: GeneralInfo.fromServer(props),
      individualInfo: IndividualInfo.fromServer(props),
      companyInfo: CompanyInfo.fromServer(props),
      skills: (props.relations.Skill || []).map(Skill.fromServer),
      channels: (props.relations.Channel || []).map(Channel.fromServer),
      currencies: (props.relations.Currency || [])
        .map(PrefferedCurrency.getFromSrever)
        .filter(Boolean) as Array<PrefferedCurrency>,
    })
  }
}

export type MyCustomerProfileFromServerProps = GeneralInfoServerProps & IndividualInfoServerProps & CompanyInfoServerProps & {
  type: typeof USER_TYPE_CUSTOMER_PERSON | typeof USER_TYPE_CUSTOMER_COMPANY
  first_save: number
  relations: {
    Skill: Array<SkillFromServer> | null
    Channel: Array<ChannelFromServer> | null
    Currency: Array<PrefferedCurrencyServerProps> | null
  }
}
