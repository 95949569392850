import cloneDeep from 'lodash/cloneDeep'
import unescape from 'lodash/unescape'
import Skill, { SkillFromServer } from '@/models-ts/Skill'

export default class GeneralInfo {
  firstName: string
  lastName: string
  website: string
  skills: Array<Skill>
  bio: string
  profession: string
  dob: string | null
  internalCountryId: number | null

  constructor (
    {
      firstName = '',
      lastName = '',
      website = '',
      bio = '',
      skills = [],
      internalCountryId = null,
    }: Partial<GeneralInfo>
  ) {
    Object.assign(this, cloneDeep({
      firstName,
      lastName,
      website,
      skills,
      bio,
      internalCountryId,
    }))
  }

  static fromServer (
    {
      first_name,
      last_name,
      website,
      relations,
      bio,
      internal_country_id,
    }: GeneralInfoServerProps
  ) {
    return new GeneralInfo({
      firstName: unescape(first_name || ''),
      lastName: unescape(last_name || ''),
      website: website || '',
      bio: bio || '',
      skills: (relations.Skill || []).map(Skill.fromServer),
      internalCountryId: internal_country_id,
    })
  }

  toServer () {
    return {
      first_name: this.firstName.trim(),
      last_name: this.lastName.trim(),
      bio: this.bio.trim(),
      website: this.website.trim(),
      internal_country_id: this.internalCountryId,
      relations: {
        Skill: this.skills.map(skill => skill.toServer()),
      }
    }
  }
}

export type GeneralInfoServerProps = {
  first_name: string | null
  last_name: string | null
  website: string | null
  bio: string | null
  internal_country_id: number | null
  relations: {
    Skill: Array<SkillFromServer> | null
  }
}
